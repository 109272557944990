import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./TrendingPage.css"; // Custom CSS for the Trending Page
import UpgradePopup from "./UpgradePopup";
import { auth } from "../firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { Helmet } from "react-helmet";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
import { FiPlus } from "react-icons/fi";

const TrendingPage = ({ updateActiveTab, handleDisplayData }) => {
  const [data, setData] = useState([]); // State for storing the fetched data
  const [user, setUser] = useState(null);
  const [filteredData, setFilteredData] = useState([]); // State for storing filtered data
  const [loading, setLoading] = useState(true); // State for handling loading state
  const [searchTerm, setSearchTerm] = useState(""); // State for the search term
  const [sortOrder, setSortOrder] = useState(""); // State to track sort order ('latest' or 'oldest')
  const [currentPage, setCurrentPage] = useState(1); // State for managing current page
  const [upgradePopup, setUpgradePopup] = useState(false);
  const itemsPerPage = 10; // Display 10 items per page
  const navigate = useNavigate();
  const baseURL =
    process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
      ? "http://127.0.0.1:5000"
      : "https://flask-backend-52245432644.us-central1.run.app";

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [user]);

  const addToFavorites = async (index) => {
    const userId = localStorage.getItem("databaseUid");
    try {
      const correctIndex = indexOfFirstItem + index; // Adjust index for full dataset
      const updatedTickers = [...filteredData];
      updatedTickers[correctIndex] = {
        ...updatedTickers[correctIndex],
        is_in_favorites: true,
      };
      setFilteredData(updatedTickers);
      await axios.get(
        `${baseURL}/add-to-favorites?uid=${userId}&ticker=${filteredData[
          correctIndex
        ].ticker.toUpperCase()}`
      );
    } catch (err) {
      console.log("Failed to add to favorites: " + err);
    }
  };

  const removeFromFavorites = async (index) => {
    const userId = localStorage.getItem("databaseUid");
    try {
      const correctIndex = indexOfFirstItem + index; // Adjust index for full dataset
      const updatedTickers = [...filteredData];
      updatedTickers[correctIndex] = {
        ...updatedTickers[correctIndex],
        is_in_favorites: false,
      };
      setFilteredData(updatedTickers);
      await axios.get(
        `${baseURL}/remove-from-favorites?uid=${userId}&ticker=${filteredData[
          correctIndex
        ].ticker.toUpperCase()}`
      );
    } catch (err) {
      console.log("Failed to remove from favorites: " + err);
    }
  };

  useEffect(() => {
    // Simulate an API call to fetch trending data
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("databaseUid");
        const favorites_response = await axios.get(`${baseURL}/get-favorites`, {
          params: { uid: userId },
          headers: {
            "Content-Type": "application/json",
          },
        });
        const response = await fetch(`${baseURL}/trending`);
        const result = await response.json();
        let trending_tickers = result.trending_tickers;
        let user_favorites = [];
        const fetchedData = favorites_response.data;
        for (let entry of fetchedData.favorites) {
          user_favorites.push(entry.ticker);
        }
        for (let entry of trending_tickers) {
          if (user_favorites.includes(entry.ticker)) {
            entry.is_in_favorites = true;
          } else {
            entry.is_in_favorites = false;
          }
        }
        console.log(trending_tickers);
        setData(trending_tickers);
        setFilteredData(trending_tickers);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching trending data", err);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Handle search term change
  useEffect(() => {
    const searchResults = data.filter((entry) =>
      entry.ticker.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(searchResults);
    setCurrentPage(1); // Reset to page 1 on search
  }, [searchTerm, data]);

  // Handle sorting by latest or oldest
  useEffect(() => {
    if (sortOrder) {
      const sortedData = [...filteredData].sort((a, b) => {
        const dateA = new Date(a.researchDate);
        const dateB = new Date(b.researchDate);
        return sortOrder === "latest" ? dateB - dateA : dateA - dateB;
      });
      setFilteredData(sortedData);
    }
  }, [sortOrder, filteredData]);

  // Handle pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleViewPrediction = async (prediction_id, index) => {
    const userID = localStorage.getItem("uid");
    if (
      Number(localStorage.getItem("credits")) <
        Number(process.env.REACT_APP_CREDITS_TO_SUBTRACT_TRENDING) &&
      userID !== data[index].user_id
    ) {
      setUpgradePopup(true);
    } else {
      updateActiveTab("analyze");
      navigate(`/dashboard?pid=${prediction_id}`);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Adjust for timezone offset to get the correct local date
    const localDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60000
    );
    return localDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  return (
    <div>
      <Helmet>
        <title>Trending AI-based Stock Analyses Today</title>
        <meta
          name="description"
          content="Stay updated on top movers in the market. Explore trending stocks and real-time AI insights with MarketCrunch AI"
        />
      </Helmet>
      <UpgradePopup
        isOpen={upgradePopup}
        handleClose={() => setUpgradePopup(false)}
      />
      <div className="trending-page-container">
        {/* Minimal Headline */}

        <p className="trending-disclaimer">
          {" "}
          View AI analysis from other users. Costs{" "}
          {process.env.REACT_APP_CREDITS_TO_SUBTRACT_TRENDING} credit per view.
        </p>
        <br />

        <div className="trending-container">
          {/* Inside the box: Header, Search, Filter */}
          <div className="header-row">
            <div className="right-side-controls">
              <input
                type="text"
                className="search-bar"
                placeholder="Search by ticker symbol..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              {/* Dropdown for sorting with "Sort By" always as a placeholder */}
              <select
                className="sort-dropdown"
                value={sortOrder}
                onChange={handleSortChange}
              >
                <option value="latest">Newest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
          </div>

          <div className="trending-table-container">
            <table className="trending-table">
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Ticker</th>
                  <th></th>
                  <th>AI Analysis</th>
                  <th>Research Date</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      Loading data...
                    </td>
                  </tr>
                ) : currentData.length === 0 ? (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      No trending data found
                    </td>
                  </tr>
                ) : (
                  currentData.map((entry, index) => (
                    <tr key={index}>
                      <td>
                        {entry.name.includes(".") &&
                        entry.name.split(".").length === 4
                          ? `***.***.${entry.name.split(".")[2]}.${
                              entry.name.split(".")[3]
                            }`
                          : entry.name.split(" ").length > 1
                          ? `${entry.name.split(" ")[0].charAt(0)}${"*".repeat(
                              entry.name.split(" ")[0].length - 1
                            )} ${entry.name
                              .split(" ")[1]
                              .charAt(0)}${"*".repeat(
                              entry.name.split(" ")[1].length - 1
                            )}`
                          : entry.name.length > 1
                          ? `${entry.name.charAt(0)}${"*".repeat(
                              entry.name.length - 1
                            )}`
                          : "No name"}
                      </td>

                      <td>{entry.ticker.toUpperCase()}</td>
                      <td
                        style={{
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <button
                          onClick={() =>
                            entry.is_in_favorites
                              ? removeFromFavorites(index)
                              : addToFavorites(index)
                          }
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            padding: "5px",
                          }}
                          aria-label={
                            entry.is_in_favorites
                              ? "Remove from favorites"
                              : "Add to favorites"
                          }
                        >
                          {entry.is_in_favorites ? (
                            <FaHeart color="#FF4C61" size={20} />
                          ) : (
                            <FiPlus
                              size={20}
                              style={{ color: "black", fill: "white" }}
                            />
                          )}
                        </button>
                      </td>
                      <td>
                        <button
                          className="view-prediction"
                          onClick={() =>
                            handleViewPrediction(entry.prediction_id, index)
                          }
                        >
                          View
                        </button>
                      </td>
                      <td>{formatDate(entry.research_date)}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="pagination">
            <span>
              Showing {indexOfFirstItem + 1} to{" "}
              {indexOfLastItem < filteredData.length
                ? indexOfLastItem
                : filteredData.length}{" "}
              of {filteredData.length} entries
            </span>
            <div className="pagination-controls">
              <button
                className="page-btn"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                &lt;
              </button>
              <button className="page-btn active">{currentPage}</button>
              <button
                className="page-btn"
                onClick={handleNextPage}
                disabled={
                  currentPage >= Math.ceil(filteredData.length / itemsPerPage)
                }
              >
                &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingPage;
