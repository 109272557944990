import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import DOMPurify from "dompurify";
import "./PredictionOutput.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { FiArrowUp, FiMinus, FiArrowDown } from "react-icons/fi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReddit } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { ClipLoader } from "react-spinners"; // Import the spinner
import LivePriceCard from "./LivePriceCard";
import TechnicalIndicatorsPlot from "./TechnicalIndicatorsPlot"; // Import TechnicalIndicatorsPlot

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
};

const CollapsibleSection = ({ question, answer, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="collapsible-section">
      <div
        className="collapsible-question"
        onClick={() => setIsOpen((prev) => !prev)}
        style={{
          fontWeight: "bold",
          cursor: "pointer",
          borderBottom: "1px solid #ddd",
          padding: "10px 0",
        }}
      >
        {question} {isOpen ? "▲" : "▶"}
      </div>
      {isOpen && (
        <div
          className="collapsible-answer"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      )}
    </div>
  );
};

const holidays = new Set([
  "2024-01-01",
  "2024-01-15",
  "2024-02-19",
  "2024-03-29",
  "2024-05-27",
  "2024-06-19",
  "2024-07-04",
  "2024-09-02",
  "2024-11-28",
  "2024-12-25",
]);

const isTradingDay = (date) => {
  const dayOfWeek = date.getUTCDay(); // Use UTC to avoid time zone issues
  const formattedDate = date.toISOString().split("T")[0];
  return dayOfWeek !== 0 && dayOfWeek !== 6 && !holidays.has(formattedDate);
};

const calculateTradingDays = (firstTradeDate) => {
  if (!firstTradeDate) return null;

  const firstTradeDateObj = new Date(firstTradeDate);
  const today = new Date();

  let tradingDays = 0;
  let currentDate = new Date(firstTradeDateObj);

  while (currentDate <= today) {
    if (isTradingDay(currentDate)) {
      tradingDays++;
    }
    currentDate.setUTCDate(currentDate.getUTCDate() + 1); // Use UTC to avoid time shifts
  }

  return new Intl.NumberFormat("en-US").format(tradingDays);
};

const extractNewsSection = (gptOutput) => {
  if (!gptOutput) return null;

  // Find the news section using HTML tag pattern
  const newsTag = "<p><b>News</b></p>\n<p>";
  const newsStart = gptOutput.indexOf(newsTag);
  const insiderStart = gptOutput.indexOf("<p><b>Insider Trading</b></p>");

  if (newsStart === -1) return null;

  // Get the news section content starting after the tag
  const newsContentStart = newsStart + newsTag.length;
  const newsEnd = insiderStart !== -1 ? insiderStart : gptOutput.length;
  const newsSection = gptOutput.substring(newsContentStart, newsEnd).trim();

  return newsSection;
};

const extractTechnicalAnalysis = (gptOutput) => {
  if (!gptOutput) return "No technical analysis available.";

  // Find the technical analysis section using HTML tag pattern
  const techStartTag = "\n\n<p><b>Technical Analysis</b></p>\n<p>\n";
  const techEndTag = "\n</p>\n\n<p><b>News</b></p>\n<p>";

  const techStart = gptOutput.indexOf(techStartTag);
  const techEnd = gptOutput.indexOf(techEndTag);

  if (techStart === -1) return "No Insights on technical analysis available.";

  // Get the technical analysis content starting after the tag
  const techContentStart = techStart + techStartTag.length;
  const techContentEnd = techEnd !== -1 ? techEnd : gptOutput.length;
  const techSection = gptOutput
    .substring(techContentStart, techContentEnd)
    .trim();

  return techSection || "No insight on technical analysis available.";
};

const extractInsiderTrading = (gptOutput) => {
  if (!gptOutput) return "No insider trading information available.";

  // Find the insider trading section using HTML tag pattern
  const insiderTag = "<p><b>Insider Trading</b></p>\n<p>\n";
  const insiderStart = gptOutput.indexOf(insiderTag);

  if (insiderStart === -1) return "No insider trading information available.";

  // Get the insider trading content starting after the tag
  const insiderContentStart = insiderStart + insiderTag.length;
  const insiderSection = gptOutput.substring(insiderContentStart).trim();

  return insiderSection || "No insider trading information available.";
};

const PredictionOutput = ({
  gptOutput,
  featureImportance,
  positiveFeatures,
  negativeFeatures,
  ticker,
  apiData,
}) => {
  const [graphData, setGraphData] = useState([]);
  const [redditPosts, setRedditPosts] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newsText, setNewsText] = useState(""); // State to hold extracted news text
  const [firstPart, setFirstPart] = useState("");
  const [secondPart, setSecondPart] = useState("");
  const [remainingPart, setRemainingPart] = useState("");
  // const [activeSection, setActiveSection] = useState('Data');
  // const [technicalPart, setTechnicalPart] = useState('');
  const [dataAnalysisPart, setDataAnalysisPart] = useState("");
  const [technicalAnalysisPart, setTechnicalAnalysisPart] = useState("");
  const [redditLoading, setRedditLoading] = useState(true); // State for Reddit loading
  const [newsLoading, setNewsLoading] = useState(true);
  const [nDays, setNDays] = useState(null);
  const [insiderTradingPart, setInsiderTradingPart] = useState("");
  const [showAllNews, setShowAllNews] = useState(false);
  const graphContainerRef = useRef(null);
  const [graphHeight, setHeight] = useState(400);
  const [showFullSummary, setShowFullSummary] = useState(false);
  const width = useWindowWidth();

  useEffect(() => {
    if (graphContainerRef.current) {
      graphContainerRef.current.offsetHeight > 400
        ? setHeight(400)
        : setHeight(graphContainerRef.current.offsetHeight);
    }
    const handleResize = () => {
      if (graphContainerRef.current) {
        graphContainerRef.current.offsetHeight > 400
          ? setHeight(400)
          : setHeight(graphContainerRef.current.offsetHeight);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const firstTradeDate = apiData?.first_trade_date;

  useEffect(() => {
    if (!ticker) return;

    let isCurrent = true; // Flag to track the current request
    setGraphData([]); // Reset graphData when ticker changes
    setError(null); // Clear any previous errors
    setLoading(true);

    const fetchData = async () => {
      try {
        const baseURL =
          process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
            ? "http://127.0.0.1:5000"
            : "https://flask-backend-52245432644.us-central1.run.app";

        const response = await axios.get(`${baseURL}/actuals-data`, {
          params: { ticker },
        });

        if (!isCurrent) return; // Ignore if this is not the latest request

        const result = response.data.data;
        if (result && result.length > 0) {
          const formattedData = result.map((row) => ({
            Date: row.date,
            Prediction_Value: Number(row.prediction_value),
            Close: Number(row.close),
          }));
          setGraphData(formattedData);
        } else {
          setError("No model fit information available yet");
        }
      } catch (err) {
        if (isCurrent) setError("Failed to load data");
      } finally {
        if (isCurrent) setLoading(false);
      }
    };

    fetchData();

    return () => {
      isCurrent = false; // Cancel this request if the component unmounts or ticker changes
    };
  }, [ticker]);

  const fetchRedditData = async (ticker) => {
    const baseURL =
      process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
        ? "http://127.0.0.1:5000"
        : "https://flask-backend-52245432644.us-central1.run.app";

    try {
      const response = await axios.post(`${baseURL}/fetch-reddit-posts`, {
        ticker: ticker,
        keywords: ["buy", "sell", "trade", "stock", "investment", "shares"],
        limit: 25,
      });

      if (response.data && response.data.reddit_posts) {
        return response.data.reddit_posts;
      } else {
        console.error("No Reddit posts found.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching Reddit posts:", error);
      return [];
    }
  };

  const fetchNewsData = async (ticker) => {
    const baseURL =
      process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
        ? "http://127.0.0.1:5000"
        : "https://flask-backend-52245432644.us-central1.run.app";

    try {
      const response = await axios.post(`${baseURL}/fetch-news-data`, {
        ticker: ticker,
      });

      if (response.data && response.data.news_data) {
        return response.data.news_data;
      } else {
        console.error("No news data found.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching news data:", error);
      return [];
    }
  };

  useEffect(() => {
    if (!ticker) return;

    const getRedditPosts = async () => {
      setRedditLoading(true); // Set loading state for Reddit data
      const redditData = await fetchRedditData(ticker);
      setRedditPosts(redditData);
      setRedditLoading(false); // Set loading state to false once data is fetched
    };
    getRedditPosts();

    const getNewsData = async () => {
      setNewsLoading(true);
      const newsData = await fetchNewsData(ticker);
      setNewsData(newsData);
      setNewsLoading(false);
    };
    getNewsData();
  }, [ticker]);

  // # Frontend modifications
  const formatTimeDifference = (timestamp) => {
    if (!timestamp) return "No recent comments";

    const now = new Date();
    const date = new Date(timestamp * 1000);
    const diffSeconds = Math.floor((now - date) / 1000);

    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
    };

    for (const [unit, secondsInUnit] of Object.entries(intervals)) {
      const interval = Math.floor(diffSeconds / secondsInUnit);
      if (interval >= 1) {
        return `${interval} ${unit}${interval === 1 ? "" : "s"} ago`;
      }
    }

    return "Just now";
  };

  const formatValue = (value) => {
    return typeof value === "number" && !isNaN(value)
      ? `$${value.toFixed(2)}`
      : "N/A";
  };

  const splitGptOutput = (gptOutput) => {
    if (!gptOutput || typeof gptOutput !== "string") {
      throw new Error("Invalid GPT output provided");
    }

    // Sanitize and parse the HTML content
    const sanitizedOutput = DOMPurify.sanitize(gptOutput);
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedOutput, "text/html");

    // Locate paragraphs and try to find the "News:" keyword
    const paragraphs = Array.from(doc.querySelectorAll("p"));
    let newsStartIndex = -1;
    let newsEndIndex = -1;

    paragraphs.forEach((p, index) => {
      if (p.textContent.trim().toLowerCase().includes("news:")) {
        newsStartIndex = index;
      }
      // Optional: use a marker like "insider trading" to end the news section
      if (
        newsStartIndex !== -1 &&
        p.textContent.trim().toLowerCase().includes("insider trading")
      ) {
        newsEndIndex = index;
      }
    });

    if (newsStartIndex === -1) {
      // No news section found – set newsText as an empty string
      setNewsText("");
      // Use the entire output as the first part
      const firstPart = paragraphs.map((p) => p.outerHTML).join("");
      if (!firstPart.trim()) {
        throw new Error("Mandatory section 'First Part' (content) is missing.");
      }
      setFirstPart(firstPart);
      setRemainingPart("");

      const { dataAnalysisPart, technicalAnalysisPart } =
        splitFirstPart(firstPart);
      if (!dataAnalysisPart.trim()) {
        throw new Error("Mandatory section 'Data Analysis Part' is empty.");
      }
      setDataAnalysisPart(dataAnalysisPart);
      if (!technicalAnalysisPart.trim()) {
        throw new Error(
          "Mandatory section 'Technical Analysis Part' is empty."
        );
      }
      setTechnicalAnalysisPart(technicalAnalysisPart);
      return;
    }

    // If a news section is found, extract the news content
    const newsContent = paragraphs
      .slice(newsStartIndex, newsEndIndex !== -1 ? newsEndIndex : undefined)
      .map((p) => p.textContent.trim().replace(/^news:\s*/i, ""))
      .join(" ");

    // Instead of throwing an error for empty news content, set it to an empty string
    setNewsText(newsContent.trim() ? newsContent : "");

    // Extract text before the "News:" section
    const firstPart = paragraphs
      .slice(0, newsStartIndex)
      .map((p) => p.outerHTML)
      .join("");
    if (!firstPart.trim()) {
      throw new Error(
        "Mandatory section 'First Part' (before News) is missing."
      );
    }
    setFirstPart(firstPart);

    // Extract text after the news section if any exists
    const remainingPart =
      newsEndIndex !== -1
        ? paragraphs
            .slice(newsEndIndex)
            .map((p) => p.outerHTML)
            .join("")
        : "";
    setRemainingPart(remainingPart);

    // Now split the first part into Data Analysis and Technical Analysis sections
    const { dataAnalysisPart, technicalAnalysisPart } =
      splitFirstPart(firstPart);

    if (!dataAnalysisPart.trim()) {
      throw new Error("Mandatory section 'Data Analysis Part' is empty.");
    }
    setDataAnalysisPart(dataAnalysisPart);

    if (!technicalAnalysisPart.trim()) {
      throw new Error("Mandatory section 'Technical Analysis Part' is empty.");
    }
    setTechnicalAnalysisPart(technicalAnalysisPart);
  };

  const splitFirstPart = (firstPart) => {
    if (!firstPart || typeof firstPart !== "string") {
      throw new Error("Invalid First Part provided.");
    }

    // Sanitize and parse the HTML content of firstPart
    const sanitizedFirstPart = DOMPurify.sanitize(firstPart);
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedFirstPart, "text/html");

    // Locate paragraphs within the firstPart
    const paragraphs = Array.from(doc.querySelectorAll("p"));
    let technicalStartIndex = -1;

    // Identify where "Technical Analysis" starts
    paragraphs.forEach((p, index) => {
      if (p.textContent.trim().toLowerCase().includes("technical analysis")) {
        technicalStartIndex = index;
      }
    });

    // If "Technical Analysis" is not found, return a default message
    if (technicalStartIndex === -1) {
      return {
        dataAnalysisPart: paragraphs.map((p) => p.textContent.trim()).join(" "),
        technicalAnalysisPart: "No technical analysis available.",
      };
    }

    // Split the content based on the identified technical analysis section
    const dataAnalysisPart = paragraphs
      .slice(0, technicalStartIndex)
      .map((p) => p.textContent.trim())
      .join(" ")
      .replace(/^What data was analyzed\?/i, "");

    const technicalAnalysisPart = paragraphs
      .slice(technicalStartIndex)
      .map((p) => p.textContent.trim())
      .join(" ")
      .replace(/^Technical Analysis\s*/i, "");

    return { dataAnalysisPart, technicalAnalysisPart };
  };

  useEffect(() => {
    splitGptOutput(gptOutput); // Extract news text and split gptOutput
  }, [gptOutput]);

  useEffect(() => {}, [redditPosts]); // Run whenever redditPosts changes

  const topRedditPosts = Array.isArray(redditPosts)
    ? redditPosts
        .sort((a, b) => b.created_utc - a.created_utc) // Sort by most recent (timestamp)
        .slice(0, 5) // Limit to top 5 posts
    : [];

  useEffect(() => {
    if (firstTradeDate) {
      setNDays(calculateTradingDays(firstTradeDate));
    }
  }, [firstTradeDate]);

  const sortedNewsData = newsData
    ? [...newsData].sort(
        (a, b) => new Date(b["published date"]) - new Date(a["published date"])
      )
    : [];
  const displayedNews = showAllNews
    ? sortedNewsData
    : sortedNewsData.slice(0, 5);

  const newsAnalysis = extractNewsSection(gptOutput);

  useEffect(() => {
    if (gptOutput) {
      const techAnalysis = extractTechnicalAnalysis(gptOutput);
      setTechnicalAnalysisPart(techAnalysis);
    }
  }, [gptOutput]);

  useEffect(() => {
    if (gptOutput) {
      const insiderActivity = extractInsiderTrading(gptOutput);
      setInsiderTradingPart(insiderActivity);
    }
  }, [gptOutput]);

  function formatNumber(num) {
    const absNum = Math.abs(Number(num));
    const sign = num < 0 ? "-" : "";
    const trillion = 1e12; // 1,000,000,000,000
    const billion = 1e9; // 1,000,000,000
    const million = 1e6; // 1,000,000

    if (absNum >= trillion) {
      return `${sign}${(absNum / trillion).toFixed(2)}T`;
    } else if (absNum >= billion) {
      return `${sign}${(absNum / billion).toFixed(2)}B`;
    } else if (absNum >= million) {
      return `${sign}${(absNum / million).toFixed(2)}M`;
    } else {
      return `${sign}${absNum.toLocaleString("en-US")}`;
    }
  }

  return (
    <div className="output">
      {/* <div className="prediction-text">
        <div className="gpt-output-contents">
          <h3>Data Analyzed</h3>
          <br />
          <ul className="data-analysis-list">
            <li>
              {nDays !== null
                ? `${nDays} days of trading data since ${firstTradeDate}`
                : "Loading..."}
            </li>
            <li>Technical Indicators (MACD, RSI, and 20 others)</li>
            <li>Long-term Indicators (10Y Treasury, etc.)</li>
            <li>Proprietary data</li>
          </ul>
        </div>
      </div> */}

      <div className="prediction-output-container">
        <div className="prediction-output-container">
          <div
            style={{
              display: "flex",
              flexDirection: width < 485 ? "column" : "row",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: 20,
              alignItems: "flex-start", // Prevent height coupling
            }}
          >
            <div
              className="technical-container"
              style={{
                width: width < 485 ? "100%" : "calc(50% - 10px)",
                marginTop: 0,
              }}
            >
              <h3>Company Background</h3>
              <br />
              <div style={{ marginBottom: 10 }}>
                <span
                  style={{
                    display: "-webkit-box", // Required for line-clamp
                    WebkitBoxOrient: "vertical", // Required for line-clamp
                    WebkitLineClamp: showFullSummary ? "none" : 2, // Limits to 12 lines when collapsed
                    overflow: "hidden", // Hides excess text
                    maxHeight: showFullSummary ? "none" : "300px", // For transition and compatibility
                    transition: "max-height 0.3s ease", // Smooth expansion
                    lineHeight: "1.5", // Consistent line height for cleaner cutoff
                    fontFamily: "Merriweather",
                    textAlign: "start",
                  }}
                >
                  {apiData.company_info.longBusinessSummary}
                </span>
                <span
                  style={{
                    cursor: "pointer",
                    color: "#1D3557",
                    textDecoration: "underline",
                    display: "block",
                    marginTop: 5,
                    marginBottom: 10,
                    fontSize: 15,
                    fontFamily: "Merriweather",
                    textAlign: "start",
                  }}
                  onClick={() => setShowFullSummary(!showFullSummary)}
                >
                  {showFullSummary ? "Read Less" : "Read More"}
                </span>
              </div>
              <div>
                <div className="company-info-stat-container">
                  <span className="company-info-stat-title">Headquarters:</span>
                  <span className="company-info-stat-value">
                    {apiData.company_info.city}, {apiData.company_info.state},{" "}
                    {apiData.company_info.country == "United States"
                      ? "USA"
                      : apiData.company_info.country}
                  </span>
                </div>
                <div className="company-info-stat-container">
                  <span className="company-info-stat-title">Sector:</span>
                  <span className="company-info-stat-value">
                    {apiData.company_info.sector}
                  </span>
                </div>
                <div className="company-info-stat-container">
                  <span className="company-info-stat-title">Industry:</span>
                  <span className="company-info-stat-value">
                    {apiData.company_info.industry}
                  </span>
                </div>
                <div className="company-info-stat-container">
                  <span className="company-info-stat-title">Employees:</span>
                  <span className="company-info-stat-value">
                    {apiData.company_info.employees}
                  </span>
                </div>
                <div className="company-info-stat-container">
                  <span className="company-info-stat-title">Exchange:</span>
                  <span className="company-info-stat-value">
                    {apiData.company_info.exchange}
                  </span>
                </div>
                <div className="company-info-stat-container">
                  <span className="company-info-stat-title">
                    Dividend Yield:
                  </span>
                  <span className="company-info-stat-value">
                    {apiData.company_info.dividendYield}
                  </span>
                </div>
                {width > 485 && (
                  <>
                    <div className="company-info-stat-container">
                      <span className="company-info-stat-title">
                        Market Cap:
                      </span>
                      <span className="company-info-stat-value">
                        {formatNumber(apiData.company_info.marketCap)}
                      </span>
                    </div>
                    <div className="company-info-stat-container">
                      <span className="company-info-stat-title">
                        52-Week High:
                      </span>
                      <span className="company-info-stat-value">
                        $
                        {Number(apiData.company_info.fiftyTwoWeekHigh).toFixed(
                          2
                        )}
                      </span>
                    </div>
                    <div className="company-info-stat-container">
                      <span className="company-info-stat-title">
                        52-Week Low:
                      </span>
                      <span className="company-info-stat-value">
                        $
                        {Number(apiData.company_info.fiftyTwoWeekLow).toFixed(
                          2
                        )}
                      </span>
                    </div>
                    <div className="company-info-stat-container">
                      <span className="company-info-stat-title">
                        Forward P/E:
                      </span>
                      <span className="company-info-stat-value">
                        {Number(apiData.company_info.forwardPE).toFixed(1)}
                      </span>
                    </div>
                    <div className="company-info-stat-container">
                      <span className="company-info-stat-title">
                        Trailing P/E:
                      </span>
                      <span className="company-info-stat-value">
                        {Number(apiData.company_info.trailingPE).toFixed(1)}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <LivePriceCard
              width={width}
              ticker={ticker}
              lastClosePrice={apiData.current_close}
            />
          </div>
          
          {/* Technical Signal message */}
          {/*
          {technicalSignal && (
            <div className="technical-signal-container" style={{
              marginBottom: '20px',
              padding: '12px 15px',
              borderRadius: '6px',
              backgroundColor: '#f5f7fa',
              border: '1px solid #e1e5eb',
              fontFamily: 'Merriweather, serif',
              fontSize: '15px',
              color: '#1D3557',
              boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
              textAlign: 'left'
            }}>
              {technicalSignal}
            </div>
          )}
          */}
        </div>
        <div className="second-tab">
          <div className="quant-engine-card">
            <TechnicalIndicatorsPlot width={width} ticker={ticker} />
          </div>
          <div className="quant-left-column">
            {/* Graph container */}
            {!loading && !error && graphData.length > 0 && (
              <div
                className="predictiion-graph-container"
                ref={graphContainerRef}
              >
                <div className="graph-container">
                  <h3 style={{ 
                    fontFamily: 'Merriweather, serif',
                    fontSize: '20px',
                    color: '#1D3557',
                    fontWeight: '600',
                    marginBottom: '15px'
                  }}>
                    AI-Model Fit: Predicted v. Actual
                  </h3>
                  <ResponsiveContainer height={graphHeight}>
                    <LineChart data={graphData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="Date"
                        textAnchor="end"
                        tickFormatter={(date) => {
                          const dateObj = new Date(date);
                          if (isNaN(dateObj.getTime())) return "";
                          const month = String(dateObj.getMonth() + 1).padStart(
                            2,
                            "0"
                          );
                          const day = String(dateObj.getDate()).padStart(
                            2,
                            "0"
                          );
                          return `${month}/${day}`;
                        }}
                      />
                      <YAxis
                        domain={["auto", "auto"]}
                        tickFormatter={(value) => `$${Math.round(value)}`}
                      />
                      <Tooltip
                        formatter={(value, name) => [formatValue(value), name]}
                      />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="Prediction_Value"
                        stroke="#1D3557"
                        name="Prediction"
                        strokeWidth={3}
                        dot={false}
                        strokeDasharray="5 5"
                      />
                      <Line
                        type="monotone"
                        dataKey="Close"
                        stroke="#139C8D"
                        name="Actual"
                        strokeWidth={3}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            )}
            <div className="technical-container">
              <h3>Technical Analysis</h3>
              {/* Influences container */}
              <div className="influences-container">
                <div className="influence-box influence-positive">
                  <div className="icon">+</div>
                  <div className="influence-text">
                    <h3>Positive Influences</h3>
                    <ul>
                      {positiveFeatures ? (
                        positiveFeatures.map((feature, index) => (
                          <li key={index}>
                            {feature}: +
                            {featureImportance
                              .find((f) => f.Feature === feature)
                              ?.Importance?.toFixed(3) || "N/A"}
                          </li>
                        ))
                      ) : (
                        <li>No positive influences</li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="influence-box influence-negative">
                  <div className="icon">-</div>
                  <div className="influence-text">
                    <h3>Negative Influences</h3>
                    <ul>
                      {negativeFeatures ? (
                        negativeFeatures.map((feature, index) => (
                          <li key={index}>
                            {feature}: -
                            {featureImportance
                              .find((f) => f.Feature === feature)
                              ?.Importance?.toFixed(3) || "N/A"}
                          </li>
                        ))
                      ) : (
                        <li>No negative influences</li>
                      )}
                    </ul>
                  </div>
                </div>
                <br />
              </div>
              <div className="prediction-text-container">
                <div className="gpt-output-content">
                  <CollapsibleSection
                    question="Insights from Technical Analysis"
                    answer={technicalAnalysisPart}
                  />
                  <CollapsibleSection
                    question="Insider Trading Activity"
                    answer={insiderTradingPart}
                    defaultOpen={true} // This makes it default open
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="qual-right-column">
            {/* GPT News Analysis */}
            {newsAnalysis && (
              <div className="technical-container">
                <h3 style={{ 
                  fontSize: '20px', 
                  fontFamily: 'Merriweather, serif',
                  color: '#1D3557',
                  fontWeight: '600',
                  marginBottom: '15px'
                }}>News Sentiment Analysis</h3>
                <br />
                <div
                  className="gpt-output-content"
                  style={{
                    fontFamily: "Merriweather",
                    textAlign: "start",
                    fontSize: "14px",
                    lineHeight: "1.4",
                  }}
                >
                  <p dangerouslySetInnerHTML={{ __html: newsAnalysis }}></p>
                </div>
              </div>
            )}

            {/* RSS News Feed */}
            <div className="technical-container">
              <h3>Recent News</h3>
              {newsLoading ? (
                <div className="loading-icon">
                  <ClipLoader color="#139C8D" size={50} />
                  <span className="loading-text">
                    Loading latest news for {ticker}
                  </span>
                </div>
              ) : newsData.length > 0 ? (
                <>
                  <div className="news-post-container">
                    {displayedNews.map((article, index) => (
                      <div key={index} className="news-post">
                        <a
                          href={article.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="news-post-title"
                        >
                          {article.title}
                        </a>
                        <div className="news-post-header">
                          <p>{article["publisher"].title || "N/A"}</p>
                          <span>
                            {(article["published date"].match(
                              /^\w+, \d{2} \w+ \d{4}/
                            ) || [])[0] || "N/A"}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                  {newsData.length > 5 && (
                    <div style={{ textAlign: "right", marginTop: "10px" }}>
                      <button
                        className="more-button"
                        onClick={() => setShowAllNews(!showAllNews)}
                      >
                        {showAllNews ? "Show Less" : "More"}
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="no-news-message"
                  style={{ fontFamily: "Merriweather", textAlign: "start" }}
                >
                  <p>
                    <br />
                    No recent news available for {ticker}. Please check back
                    later.
                  </p>
                </div>
              )}
            </div>

            {/* Reddit container */}
            <div className="reddit-posts-container">
              <div className="reddit-header">
                <h3 style={{ 
                  fontFamily: 'Merriweather, serif',
                  fontSize: '20px',
                  color: '#1D3557',
                  fontWeight: '600',
                  marginBottom: '15px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <FontAwesomeIcon
                    icon={faReddit}
                    className="reddit-logo"
                    style={{ color: "#FF4500", marginRight: '8px' }}
                  />
                  Recent Reddit Discussions
                </h3>
              </div>
              {redditLoading ? (
                <div className="loading-icon">
                  <ClipLoader color="#FF4500" size={50} />
                  <span className="loading-text">
                    Loading latest posts for {ticker}
                  </span>
                </div>
              ) : topRedditPosts.length > 0 ? (
                <ul>
                  {topRedditPosts.map((post, index) => (
                    <li key={index} className="reddit-post">
                      <div className="reddit-post-header">
                        <img
                          src={
                            post.author_profile_pic || "default-profile-pic-url"
                          }
                          alt="Author Logo"
                          className="author-logo"
                        />
                        <a
                          href={post.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="post-title"
                        >
                          {post.title}
                        </a>
                        {/* <div className="oldest-comment-time">
              <span>{post.latest_comment_time || "N/A"}</span>
            </div> */}
                      </div>
                      <div className="reddit-post-meta">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 10,
                          }}
                        >
                          <span className="meta-item">
                            <FiArrowUp style={{ color: "#28a745" }} />
                          </span>
                          <span className="meta-item">{post.score}</span>
                          <span className="meta-item">
                            <FiArrowDown style={{ color: "#dc3545" }} />
                          </span>
                          <span className="meta-item">
                            💬 {post.comments_count}
                          </span>
                        </div>
                        <div>
                          <span>{post.latest_comment_time || "N/A"}</span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div
                  className="no-reddit-message"
                  style={{ fontFamily: "Merriweather", textAlign: "start" }}
                >
                  <p>
                    No recent Reddit discussions found for {ticker}. Please
                    check back later.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PredictionOutput;
